<script setup>
import {Head, Link, useForm} from '@inertiajs/vue3';
import AuthenticationCard from '@/Components/AuthenticationCard.vue';
import AuthenticationCardLogo from '@/Components/AuthenticationCardLogo.vue';
import Checkbox from '@/Components/Checkbox.vue';
import InputError from '@/Components/InputError.vue';
import InputLabel from '@/Components/InputLabel.vue';
import PrimaryButton from '@/Components/PrimaryButton.vue';
import Socialstream from '@/Components/Socialstream.vue';
import TextInput from '@/Components/TextInput.vue';

defineProps({
	canResetPassword: Boolean,
	status: String,
});

const form = useForm({
	email: '',
	password: '',
	remember: false,
});

const submit = () => {
	form.transform(data => ({
		...data,
		remember: form.remember ? 'on' : '',
	})).post(route('login'), {
		onFinish: () => form.reset('password'),
	});
};
</script>

<template>
	<Head title="Log in"/>

	<AuthenticationCard>

		<h1 class="text-3xl font-bold pb-8">Login</h1>

		<div v-if="status" class="mb-4 font-medium text-sm text-green-600">
			{{ status }}
		</div>

		<form @submit.prevent="submit">
			<div>
				<InputLabel for="email" value="Email"/>
				<TextInput
					id="email"
					v-model="form.email"
					autofocus
					class="mt-1 block w-full"
					required
					type="email"
				/>
				<InputError :message="form.errors.email" class="mt-2"/>
			</div>

			<div class="mt-4">
				<InputLabel for="password" value="Password"/>
				<TextInput
					id="password"
					v-model="form.password"
					autocomplete="current-password"
					class="mt-1 block w-full"
					required
					type="password"
				/>
				<InputError :message="form.errors.password" class="mt-2"/>
			</div>

			<div class="block mt-4">
				<label class="flex items-center">
					<Checkbox v-model:checked="form.remember" name="remember"/>
					<span class="ml-2 text-sm text-dark-100">Remember me</span>
				</label>
			</div>

			<PrimaryButton :class="{ 'opacity-25': form.processing }" :disabled="form.processing"
			               class="!text-base w-full mt-4">
				Log in
			</PrimaryButton>
		</form>

		<div class="flex items-center justify-between mt-4">
			<Link :href="route('register')" class="underline text-sm font-medium text-dark-200 hover:text-dark-300">
				Don't have an account yet?
			</Link>

			<Link v-if="canResetPassword" :href="route('password.request')"
			      class="underline text-sm font-medium text-dark-200 hover:text-dark-300">
				Forgot your password?
			</Link>
		</div>

		<Socialstream v-if="$page.props.socialstream.show && $page.props.socialstream.providers.length"
		              :error="$page.props?.errors?.socialstream || null" :labels="$page.props.socialstream.labels"
		              :prompt="$page.props.socialstream.prompt" :providers="$page.props.socialstream.providers"/>
	</AuthenticationCard>
</template>
